import * as React from 'react'
import Layout from "../components/Layout"
import styled from 'styled-components'
import Seo from '../components/Seo'

import {GiCycling} from 'react-icons/gi'
import {GiMountainCave} from 'react-icons/gi'
import {GiSandsOfTime} from 'react-icons/gi'
import { StaticImage } from 'gatsby-plugin-image'

const All = styled.section`
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 5% 0px 5%;;


    h1 {
        font-weight: 100;
        text-align: center;
        margin: auto;
        /* text-transform: uppercase; */

        span{
            font-weight: 500;
            color: #34D277;
        }
    }
    
    h3{
        font-weight: 300;
        text-align: center;
        margin-top: 10%;
        margin: auto;
        max-width: 600px;
    }
    p{
        text-align: center;
    }
`

const Track = styled.article`
    display: block;
    justify-content: center;
    padding: 5% 10%;
    width: 100%;

    .frame{
        padding: 20px 0;
        width: 1px; 
        min-width: 100%; 
        min-height: 400px; 
        border: none; 
        ${({theme})=> theme.media.tablet}{
            height: 700px;
        }
    }

    h1{
        text-transform: uppercase;
        text-decoration: underline;
        font-family: "Righteous", cursive;
        font-size: 2.5em;
        padding-bottom: 20px;
        text-align: center;
    }

    .all{
        display: flex;
        justify-content: space-around;

        .ico{
            text-align: center;
        }

        .icon{
            width: 64px;
            height: 64px;
        }
    }
`

const Art = styled.article`
    text-align: center;
`

const TrasaImg = styled.div`
    .img{
        padding: 20px 0;
        /* width: 1px;  */
        width: 100%; 
        /* max-height: 400px;  */
        border: none; 
        ${({theme})=> theme.media.tablet}{
            /* height: 400px; */
        }
    }
`

const Trasa = () => {
    return(
        <Layout>
            <Seo title='Trasa Ultra Maratonu' keyword='trasa'/>
            <All>
                <h1>Wstępna Trasa <span>Ultra Race Roztocze 2025*</span></h1><br/>
                {/* <h3>Trasa maratonu wiedzie malowniczymi drogami. Pozostawi Ci fascynujące wrażenia i wspomnienia. A spotkane po drodze osoby mogą zaowocować przyjaźnią na całe życie.</h3> */}

                <Art>W 1643 r Jan Zamojski pozwolił na przeniesienie huty wypalającej żelazo na uroczysko zwane Susiec, oraz zezwolił na założenie stawu, który jest do dzisiaj. 12 maja 2023 roku Susiec będzie dla Was początkiem i końcem podróży, bólu, radości, łez, satysfakcji.<br></br><br></br>
                
                Tutaj rozpoczniecie swoją gravelową przygodę, tutaj staniecie u początku swojego wyzwania. Będziecie szutrować wśród sosnowych i bukowych lasów z szumiącą w pobliżu rzeką Jelonek. Ale już po paru kilometrach jazdy zbliżycie się do urokliwej i dzikiej rzeki Tanew. Pierwszego dnia spotkacie jedne z najciekawszych i najpiękniejszych miejsc Roztocza. Dzielić je będą rozciągnięte kolorowe pola zbóż i łąk, pachnące lasy, kresowe wioseczki. Trasę zaplanowaliśmy abyście zobaczyli perełki Roztocza: Józefów, Krasnobród, Zwierzyniec, Szczebrzeszyn oraz Biłgoraj, Zamość, Tomaszów Lubelski, Bełżec. To miasta i miasteczka, które chętnie zatrzymają Was na dłużej niż tylko parę minut. Ilość atrakcji i niespodzianek wciąga do swojego świata na wiele godzin, prowadząc uliczkami i opowiadając ciekawą historię. Jadąc rowerem po szutrowych drogach odwiedzicie min.: Roztoczański Park Narodowy, Rezerwat Przyrody nad Tanwią, Szczebrzeszyński Park Krajobrazowy, Lasy Janowskie. A kiedy przejedziecie swoją gravelową luxtorpedą pędem pośród bunkrów Linii Mołotowa, historia II Wojny Światowej podniesie pył wschodniego piasku i zazgrzyta w piastach niczym gąsienica sowieckiego czołgu. Bądźcie czujni na każdym kilometrze, wiele niespodzianek kryją w sobie lasy wschodnich kresów. 
                </Art>
                <Track>
                    <h1>Gigante</h1>
                    <div className='all'>
                        <div className='ico'>
                            <GiCycling className='icon'/>
                            <p>590km<br></br>przygody</p>         
                        </div>
                        <div className='ico'>
                            <GiMountainCave className='icon'/>
                            <p>4000m<br></br>wspinaczki</p>         
                        </div>
                        <div className='ico'>
                            <GiSandsOfTime className='icon'/>
                            <p>80h<br></br>limitu czasu</p>         
                        </div>
                    </div>
                    <div className='text-align: center'>
                    <p>DWA PIT-STOPY <br/>
                        ciepła zupa, przekąski, woda, herbata, kawa, miejsce do spania, prysznice</p>
                    </div>
                    <TrasaImg>
                    <StaticImage 
                        placeholder="blurred"
                        alt="more ultra race"
                        // transformOptions={{ fit: "fill", cropFocus: "entropy" }}
                        // height={400}
                        className="img"
                        lcayout="constrained"
                        src="../assets/images/tracaG2.png"
                        formats={["auto", "webp", "avif"]} />
                    </TrasaImg>
                    {/* <iframe className='frame' src="https://ridewithgps.com/embeds?type=route&id=42600913&metricUnits=true&sampleGraph=true&distanceMarkers=true&privacyCode=YXtxQa7DtKt8RCoG" scrolling="no" title='Roztocze650'></iframe> */}
                </Track>

                <Art>
                    Jazda gravelem podczas Ultra Race Roztocze przeniesie Was pomiędzy lokalne drogi, leśne dukty, piaszczyste ścieżki. Niejednokrotnie wyrosną przed Wami krótkie ale strome podjazdy. Będziecie musieli przeprawić się przez zimną rzekę oraz zaliczyć szybki zjazd wąwozem. Jeżeli pogoda będzie dla Was łaskawa poczujecie pył i kurz w ustach, jeżeli nie to będziecie wieczorem odklejać z siebie błoto i glinę. A jeżeli spotka Was deszcz, to poczujecie jak płyniecie potokiem, w którym brakuje tylko pstrągów. Rower w trakcie tej przygody będzie Waszym najlepszym przyjacielem, tylko on pozwoli Wam przetrwać i pokonać kolejne kilometry trasy, tylko on będzie Waszą nadzieją i tylko on będzie z Wami do końca. A kiedy spotkacie na trasie współtowarzyszy gravelowej podróży przez Roztocze nie zapomnijcie wesprzeć ich uśmiechem i słowem otuchy.
                </Art>
                <Track>
                    <h1>Classico</h1>
                    <div className='all'>
                        <div className='ico'>
                            <GiCycling className='icon'/>
                            <p>350km<br></br>przygody</p>         
                        </div>
                        <div className='ico'>
                            <GiMountainCave className='icon'/>
                            <p>2500m<br></br>wspinaczki</p>         
                        </div>
                        <div className='ico'>
                            <GiSandsOfTime className='icon'/>
                            <p>60h<br></br>limitu czasu</p>         
                        </div>
                    </div>
                    <p>PIT-STOP (ciepła zupa, przekąski, woda, herbata, kawa, miejsce do spania, prysznice)</p>
                    <TrasaImg>
                        <StaticImage 
                            placeholder="blurred"
                            alt="more ultra race"
                            // transformOptions={{ fit: "fill", cropFocus: "entropy" }}
                            // height={400}
                            className="img"
                            lcayout="constrained"
                            src="../assets/images/tracaC2.png"
                            formats={["auto", "webp", "avif"]} />
                    </TrasaImg>
                    {/* <iframe className="frame"src="https://ridewithgps.com/embeds?type=route&id=42673701&metricUnits=true&sampleGraph=true&distanceMarkers=true&privacyCode=77Nku4rq2LqSsdcC" title='Roztocze350'scrolling="no"></iframe> */}
                </Track>
                <Art>Przejeżdżając wśród wsi i miasteczek wiele razy zaglądniecie do ogródków gospodarstw, pozdrowicie babcie siedzące na ławeczkach pod płotami, zrobicie zakupy w mobilnych sklepikach. Południowa część woj. Lubelskiego otuli Was sennie płynącym czasem, zaprosi do odpoczynku pod kapliczką lub w cieniu wielkiego dębu. Z empatią i serdecznością spotkacie się za każdym razem jak tylko poprosicie o pomoc lub radę. Podczas rowerowej podróży wspierajcie lokalną bazę noclegową, kameralne sklepiki, małą gastronomię. W trakcie gravelowej jazdy zaplanujcie postoje tak, aby skosztować przysmaków kuchni kresowej: cebularza, farmuszki, gołąbki z tartych ziemniaków, pierogi z kiszoną kapustą i białym serem …
                <br></br><br></br>
                Ta trasa zaplanowana jest abyście zakochali się w Roztoczu i chętnie tam wracali z rodziną, przyjaciółmi. Urocze zakątki Roztocza zostaną na długo w waszych wspomnieniach a ekscytujące przeżycia wypełnią wiele gravelowych opowieści.</Art>
                <br></br><br></br>
                <Track>
                    <h1>Piccolo</h1>
                    <div className='all'>
                        <div className='ico'>
                            <GiCycling className='icon'/>
                            <p>120km<br></br>przygody</p>         
                        </div>
                        <div className='ico'>
                            <GiMountainCave className='icon'/>
                            <p><br></br>wspinaczki</p>         
                        </div>
                        <div className='ico'>
                            <GiSandsOfTime className='icon'/>
                            <p>12h<br></br>limitu czasu</p>         
                        </div>
                    </div>
                    <TrasaImg>
                        <StaticImage 
                            placeholder="blurred"
                            alt="more ultra race"
                            // transformOptions={{ fit: "fill", cropFocus: "entropy" }}
                            // height={400}
                            className="img"
                            lcayout="constrained"
                            src="../assets/images/trasaP.png"
                            formats={["auto", "webp", "avif"]} />
                    </TrasaImg>
                    {/* <iframe className="frame"src="https://ridewithgps.com/embeds?type=route&id=42673701&metricUnits=true&sampleGraph=true&distanceMarkers=true&privacyCode=77Nku4rq2LqSsdcC" title='Roztocze350'scrolling="no"></iframe> */}
                </Track>
                <p>*To nie jest ostateczna wersja tras MORE ULTRA RACE ROZTOCZE 2025.</p>
            </All>
            
            
        </Layout>
    )
}

export default Trasa
